import { DownOutlined, PrinterOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import { ExpandIcon, Label, NoteIcon } from './styledComponents';
import React, { Fragment } from 'react';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Money from '../Money/Money';
import Notes from './Notes.svg';
import OrderStatus from '../OrderStatus';
import { getPermissionsActive } from 'utils/permissionMap';
import media from 'utils/mediaStyle';
import messages from 'containers/OrdersPage/messages';
import moment from 'moment';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { OrderType } from 'types/schema';
import translations from 'translations';

export const InfoRow = styled.div`
  margin-bottom: 4pt;
  display: flex;
  flex-direction: row;
`;

const TotalLabel = styled.span`
  color: rgba(0, 0, 0, 0.54);
  min-width: 80pt;
  margin-right: 10pt;
  ${media.md`
    width: 100%;
    margin-left: 8pt;
    text-align: right;
  `};
`;

const LabelBtn = styled.span`
  color: #f5222d;
  min-width: 80pt;
  cursor: pointer;
  text-align: right;
  margin-left: auto;
`;
const SupplierName = styled.h3``;

const Value = styled.span`
  color: ${({ color }) => (color ? color : 'rgba(0, 0, 0, 0.87)')};
  flex: 1;
  > * {
    display: inline-block;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin-top: 10px;
  ${Button} {
    margin-bottom: 8pt;
    width: 100%;
    ${media.md`
      width: auto;
      margin-left: 8pt;
    `};
  }
`;

const Price = styled.span`
  font-size: 16px;
  text-align: right;
  display: block;
`;

const ReDeliveryOrder = styled.span`
  min-width: 80pt;
  margin-bottom: 10px;
  font-size: 12px;
`;

interface OrderSummaryProps {
  isMobileSize: boolean;
  order: any;
  onCancelOrder: () => void;
  reOrderAll: () => void;
  onApproveOrder: () => void;
  onDisapproveOrder: () => void;
  onEditOrder: () => void;
  onUpdateDelivery: (data: any) => void;
  permissions: string[];
}

const componentPermissions = {
  addItemToCart: 'ORDER_PLACE',
  editPriceOrder: 'ORDER_ITEM_SET_PRICE',
  editQuantityOrder: 'ORDER_ITEM_SET_QUANTITY',
  confirmDeliveryOrder: 'ORDER_DELIVERY_CONFIRM',
  cancelOrder: 'ORDER_CANCEL',
};

export default class OrderSummary extends React.Component<OrderSummaryProps> {
  public state = { expandOrder: false };

  public onExpandOrder = () => {
    if (this.props.isMobileSize) {
      this.setState({ expandOrder: !this.state.expandOrder });
    }
  };

  public print = () => {
    const { order } = this.props;
    const date = moment(order.orderDate).utcOffset(7);
    const orderDateKey = date.format('YYYY-MM-DD');

    const lang = localStorage.getItem('lang') || 'vi';
    const env = (process.env.APP_ENV || 'qa').toLowerCase().replace('development', 'qa');
    const url = `https://s3-ap-southeast-1.amazonaws.com/print.kamereo.vn/${env}/orders-pdf/${orderDateKey}/${order.id}-BUYER-${lang}.pdf`;
    window.open(url, '_blank');
  };

  public orderType = (orderType) => {
    switch (orderType) {
      case OrderType.Gift:
        return translations(messages.thisIsGiftOrder);
      case OrderType.Sample:
        return translations(messages.thisIsSampleOrder);
      case OrderType.Redelivery:
        return translations(messages.thisIsReDeliveryOrder);
      default:
        return '';
    }
  };

  public render() {
    const { order, permissions, onApproveOrder, onEditOrder, onDisapproveOrder } = this.props;
    const orderDate = moment(order.orderDate);
    const printShouldBeReady: boolean = orderDate.isBefore(moment().subtract(1, 'minutes'));
    const isExpandOrder = this.props.isMobileSize ? this.state.expandOrder : true;
    const canApproveOrder = getPermissionsActive(['ORDER_APPROVE'], permissions);
    return (
      <Fragment>
        <Row gutter={8} style={{ marginBottom: '10pt' }} onClick={this.onExpandOrder}>
          <Col span={24}>
            <InfoRow style={{ width: '100%', justifyContent: 'space-between' }}>
              <Link to={`/suppliers/${encodeURIComponent(order.supplier.name)}`}>
                <SupplierName>{order.supplier.name}</SupplierName>
              </Link>
              {this.props.isMobileSize
                ? null
                : order &&
                  order.status !== 'PENDING_APPROVAL' && (
                    <Button
                      type="primary"
                      ghost
                      onClick={this.print}
                      disabled={!printShouldBeReady}
                      title={printShouldBeReady ? '' : <FormattedMessage {...utilsMessages.printing} />}
                    >
                      <PrinterOutlined /> <FormattedMessage {...utilsMessages.print} />
                    </Button>
                  )}
            </InfoRow>
            <InfoRow>
              <OrderStatus status={order.status} statusText={order.statusText} />
            </InfoRow>
            {this.props.isMobileSize && (
              <ExpandIcon>{this.state.expandOrder ? <UpOutlined /> : <DownOutlined />}</ExpandIcon>
            )}
          </Col>
        </Row>
        <Row gutter={8}>
          {isExpandOrder && (
            <Fragment>
              <Col lg={9} sm={12}>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...utilsMessages.orderId} />
                  </Label>
                  <Value style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.id}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.orderTime} />
                  </Label>
                  <Value>{moment(order.orderDate).format('HH:mm DD/MM/YYYY')}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.deliveryTime} />
                  </Label>
                  <Value>
                    {moment(order.deliveryTime.start).format('HH:mm')}-{moment(order.deliveryTime.end).format('HH:mm')}{' '}
                    {moment(order.deliveryTime.end).format('DD/MM/YYYY')}
                  </Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.creator} />
                  </Label>
                  <Value>{order.creator.name}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.storeName} />
                  </Label>
                  <Value>{order.store.name}</Value>
                </InfoRow>
              </Col>
              <Col lg={8} sm={12}>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...utilsMessages.paymentMethod} />
                  </Label>
                  <Value>
                    <FormattedMessage {...utilsMessages[order.paymentMethod || 'COD']} />
                  </Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.totalItems} />
                  </Label>
                  <Value>{order.items.length}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.notes} />
                  </Label>
                  <Value>
                    {order.note ? (
                      <Popover content={<pre>{order.note}</pre>}>
                        <NoteIcon src={Notes} note="true" />
                      </Popover>
                    ) : (
                      <NoteIcon src={Notes} />
                    )}
                  </Value>
                </InfoRow>
                {order.status === 'DECLINED' ? (
                  <InfoRow>
                    <Label>
                      <FormattedMessage {...messages.declineReason} />
                    </Label>
                    <Value>{order.declineReason}</Value>
                  </InfoRow>
                ) : null}
              </Col>
            </Fragment>
          )}
          <Col lg={7} sm={24}>
            <InfoRow>
              <TotalLabel>
                <FormattedMessage {...messages.total} />
              </TotalLabel>
              <Value>
                <Price>
                  <Money>{order.total}</Money>
                </Price>
              </Value>
            </InfoRow>
            {order.orderType !== OrderType.PaidOrder && (
              <Row justify="end">
                <ReDeliveryOrder>{`(${this.orderType(order.orderType)})`}</ReDeliveryOrder>
              </Row>
            )}
            {order.status !== 'PENDING_APPROVAL' && (
              <>
                <InfoRow>
                  {permissions.includes(componentPermissions.cancelOrder) &&
                    order.status &&
                    (order.status.includes('SENT') || order.status.includes('PENDING')) && (
                      <LabelBtn onClick={this.props.onCancelOrder}>
                        <FormattedMessage {...messages.cancelOrder} />
                      </LabelBtn>
                    )}
                </InfoRow>
                <Actions>
                  {permissions.includes(componentPermissions.addItemToCart) && (
                    <Button type="primary" ghost onClick={this.props.reOrderAll}>
                      <FormattedMessage {...utilsMessages.addAll} />
                    </Button>
                  )}
                </Actions>
              </>
            )}
            {order.status === 'PENDING_APPROVAL' && canApproveOrder && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="danger"
                  ghost
                  style={{ borderColor: 'transparent', paddingLeft: 0, paddingRight: 0 }}
                  onClick={onDisapproveOrder}
                >
                  <FormattedMessage {...messages.declineThisOrder} />
                </Button>
                <Actions>
                  <Button type="primary" ghost onClick={onEditOrder}>
                    <FormattedMessage {...utilsMessages.edit} />
                  </Button>
                  <Button type="primary" onClick={onApproveOrder}>
                    <FormattedMessage {...utilsMessages.approve} />
                  </Button>
                </Actions>
              </div>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}
