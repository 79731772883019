/*
 *
 * OrderDetailsPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  order: {
    items: [],
    supplier: {},
    creator: {},
    buyer: {},
    store: {},
    deliveryTime: {},
    loading: true,
  },
};

function orderDetailsPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_ORDER_REQUEST:
      return {
        ...state,
        order: {
          items: [],
          supplier: {},
          creator: {},
          buyer: {},
          store: {},
          deliveryTime: {},
          loading: true,
        },
      };
    case ActionTypes.FETCH_ORDER_SUCCESS:
    case ActionTypes.CONFIRM_ORDER_SUCCESS:
    case ActionTypes.APPROVE_ORDER_SUCCESS:
    case ActionTypes.DISAPPROVE_ORDER_SUCCESS:
    case ActionTypes.UPDATE_ORDER_SUCCESS:
    case ActionTypes.CANCEL_ORDER_SUCCESS:
    case ActionTypes.UPDATE_DELIVERY_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          ...payload,
          items: [...payload.items].sort((a: any, b: any) => a.product.name.localeCompare(b.product.name)),
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default orderDetailsPageReducer;
